import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const breadcrumbsSlice = createSlice({
  name: "breadcrumbs-slice",
  initialState: initialState,
  reducers: {
    setBreadcrumbs: (state, { payload }) => {
      if (!Array.isArray(payload)) {
        console.warn("Breadcrumbs schema is not corrent please set an Array");
        return state;
      }
      state = payload;
      return state;
    },
  },
});

export const { setBreadcrumbs } = breadcrumbsSlice.actions;
export const selectBreadcrumbs = ({ fuse }) => fuse.breadcrumbs;

export default breadcrumbsSlice.reducer;
