export const BasicFilterTip = [
  "Simple filtering options.",
  "Ideal for quick and straightforward searches.",
  "Limited customization features.",
  "Basic filters help you find general information easily.",
];

export const AdvancedFilterTip = [
  "Robust filtering capabilities.",
  "Tailored for in-depth and precise searches.",
  "Offers access to advertorial and funnel filters.",
  "Advanced filters empower you to fine-tune your search results for specific needs.",
];

export const TeamMemberTip = ["+$39 member/month"];

export default [
  {
    name: "monthly",
    intervalId: 0,
    packages: [
      {
        id: 2,
        name: "Starter",
        intervalPrice: 1,
        currency: "$",
        trialDays: 3,
        price: 49,
        intervalUnit: "month",
        fastspringId: "monthly-starter",
        description: "If you're not sure and you want to give us a try",
        services: [
          {
            isAvailable: true,
            service: "Full Library Access",
          },
          {
            isAvailable: true,
            service: "5,000 unique ads/pages",
            question: "unique"
          },
          {
            isAvailable: true,
            service: "Basic filters",
            question: "filters",
            detail: BasicFilterTip,
          },
          {
            isAvailable: false,
            service: "50 favourite ads for extension",
          },
          {
            isAvailable: false,
            service: "+ $39 member/month",
            question: "team-members",
          },
          {
            isAvailable: false,
            service: "Priority support",
          },
        ],
      },
      {
        id: 3,
        name: "Basic",
        intervalPrice: 1,
        currency: "$",
        trialDays: 3,
        price: 89,
        intervalUnit: "month",
        fastspringId: "monthly-basic",
        description: "Get started with essential tools",
        services: [
          {
            isAvailable: true,
            service: "Full Library Access",
          },
          {
            isAvailable: true,
            service: "10,000 unique ads/pages",
            question: "unique",
          },
          {
            isAvailable: true,
            service: "Basic filters",
            question: "filters",
            detail: BasicFilterTip,
          },
          {
            isAvailable: false,
            service: "∞ favourite ads for extension",
          },
          {
            isAvailable: false,
            service: "+ $39 member/month",
            question: "team-members",
          },
          {
            isAvailable: false,
            service: "Priority support",
          },
        ],
      },
      {
        id: 4,
        name: "Pro",
        intervalPrice: 1,
        currency: "$",
        trialDays: 3,
        price: 119,
        intervalUnit: "month",
        fastspringId: "monthly-pro",
        description: "The ultimate advertising solution",
        services: [
          {
            isAvailable: true,
            service: "Full Library Access",
          },
          {
            isAvailable: true,
            service: "Unlimited",
            question: "unique",
          },
          {
            isAvailable: true,
            service: "Advanced filters",
            question: "filters",
            detail: AdvancedFilterTip,
          },
          {
            isAvailable: false,
            service: "∞ favourite ads for extension",
          },
          {
            isAvailable: true,
            service: "1 team member",
            detail: TeamMemberTip,
            question: "team-members",
          },
          {
            isAvailable: true,
            service: "Priority support",
          },
          {
            isAvailable: true,
            service: 'Early access to new features'
          }
        ],
      },
      {
        id: 5,
        name: "Team",
        intervalPrice: 1,
        currency: "$",
        trialDays: 3,
        price: 299,
        intervalUnit: "month",
        fastspringId: "monthly-diamond",
        description: "For those who want everything",
        services: [
          {
            isAvailable: true,
            service: "Full Library Access",
          },
          {
            isAvailable: true,
            service: "Unlimited",
            question: "unique",
          },
          {
            isAvailable: true,
            service: "Advanced filters",
            question: "filters",
            detail: AdvancedFilterTip,
          },
          {
            isAvailable: false,
            service: "∞ favourite ads for extension",
          },
          {
            isAvailable: true,
            service: "3 team member",
            question: "team-members",
            detail: TeamMemberTip,
          },
          {
            isAvailable: true,
            service: "Priority support",
          },
          {
            isAvailable: true,
            service: 'Early access to new features'
          }
        ],
      },
    ],
  },
  {
    name: "annually",
    intervalId: 1,
    packages: [
      {
        id: 2,
        name: "Starter",
        intervalPrice: 1,
        currency: "$",
        trialDays: 3,
        price: 441,
        intervalUnit: "year",
        fastspringId: "annual-starter",
        description: "If you're not sure and you want to give us a try",
        services: [
          {
            isAvailable: true,
            service: "Full Library Access",
          },
          {
            isAvailable: true,
            service: "5,000 unique ads/pages",
            question: "unique",
          },
          {
            isAvailable: true,
            service: "Basic filters",
            question: "filters",
            detail: BasicFilterTip,
          },
          {
            isAvailable: false,
            service: "50 favourite ads for extension",
          },
          {
            isAvailable: false,
            service: "+ $39 member/month",
            question: "team-members",
          },
          {
            isAvailable: false,
            service: "Priority support",
          },
        ],
      },
      {
        id: 3,
        name: "Basic",
        intervalPrice: 1,
        currency: "$",
        trialDays: 3,
        price: 801,
        intervalUnit: "year",
        fastspringId: "annual-basic",
        description: "Get started with essential tools",
        services: [
          {
            isAvailable: true,
            service: "Full Library Access",
          },
          {
            isAvailable: true,
            service: "10,000 unique ads/pages",
            question: "unique",
          },
          {
            isAvailable: true,
            service: "Basic filters",
            question: "filters",
            detail: BasicFilterTip,
          },
          {
            isAvailable: false,
            service: "∞ favourite ads for extension",
          },
          {
            isAvailable: false,
            service: "+ $39 member/month",
            question: "team-members",
          },
          {
            isAvailable: false,
            service: "Priority support",
          },
        ],
      },
      {
        id: 4,
        name: "Pro",
        intervalPrice: 1,
        currency: "$",
        trialDays: 3,
        price: 1071,
        intervalUnit: "year",
        fastspringId: "annual-pro",
        description: "The ultimate advertising solution",
        services: [
          {
            isAvailable: true,
            service: "Full Library Access",
          },
          {
            isAvailable: true,
            service: "Unlimited",
            question: "unique",
          },
          {
            isAvailable: true,
            service: "Advanced filters",
            question: "filters",
            detail: AdvancedFilterTip,
          },
          {
            isAvailable: false,
            service: "∞ favourite ads for extension",
          },
          {
            isAvailable: true,
            service: "1 team member",
            question: "team-members",
            detail: TeamMemberTip,
          },
          {
            isAvailable: true,
            service: "Priority support",
          },
          {
            isAvailable: true,
            service: 'Early access to new features'
          }
        ],
      },
      {
        id: 5,
        name: "Team",
        intervalPrice: 1,
        currency: "$",
        trialDays: 3,
        price: 2691,
        intervalUnit: "year",
        fastspringId: "annual-diamond",
        description: "For those who want everything",
        services: [
          {
            isAvailable: true,
            service: "Full Library Access",
          },
          {
            isAvailable: true,
            service: "Unlimited",
            question: "unique",
          },
          {
            isAvailable: true,
            service: "Advanced filters",
            question: "filters",
            detail: AdvancedFilterTip,
          },
          {
            isAvailable: false,
            service: "∞ favourite ads for extension",
          },
          {
            isAvailable: true,
            service: "3 team member",
            question: "team-members",
            detail: TeamMemberTip,
          },
          {
            isAvailable: true,
            service: "Priority support",
          },
          {
            isAvailable: true,
            service: 'Early access to new features'
          }
        ],
      },
    ],
  },
];
