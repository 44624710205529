import { styled } from "@mui/material/styles";
import AfterlibLogo from "app/shared-components/AfterlibLogo";

const Root = styled("div")(({ theme }) => ({
  "& > .logo-icon": {
    transition: theme.transitions.create(["width", "height"], {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
  },
  "& > .badge": {
    transition: theme.transitions.create("opacity", {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
  },
}));

function Logo() {

  return (
    <Root className="flex items-center">
      <AfterlibLogo className="logo-icon h-24 overflow-hidden" />
    </Root>
  );
}

export default Logo;
