import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import { selectUser, selectUserProps } from "app/store/userSlice";
import JwtService from "app/auth/services/jwtService";

function UserMenu(props) {
  const userProps = useSelector(selectUserProps);
  const user = useSelector(selectUser);
  const [userMenu, setUserMenu] = useState(null);
  const userMenuClick = (event) => {
    setUserMenu(event.currentTarget);
  };

  const userMenuClose = () => {
    setUserMenu(null);
  };

  return (
    <>
      <Button
        className="min-h-40 min-w-40 px-0 md:px-16 py-0 md:py-6"
        onClick={userMenuClick}
        color="inherit"
      >
        <div className="hidden md:flex flex-col mx-4 items-end">
          <Typography
            component="span"
            color="text.primary"
            className="font-median flex text-14"
          >
            {userProps?.firstName ? userProps?.firstName : userProps?.email}
          </Typography>
          {/* <Typography className="text-11 font-medium capitalize" color="text.secondary">
            {user.role.toString()}
            {(!user.role || (Array.isArray(user.role) && user.role.length === 0)) && 'Guest'}
          </Typography> */}
        </div>
        <Avatar
          className="md:mx-4"
          style={{ backgroundColor: "#50CD89", fontSize: "16px" }}
          sx={{ width: 32, height: 32 }}
        >
          {userProps?.firstName
            ? userProps?.firstName[0].toUpperCase()
            : userProps?.email[0].toUpperCase()}
        </Avatar>
      </Button>

      <Popover
        open={Boolean(userMenu)}
        anchorEl={userMenu}
        onClose={userMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{
          paper: "py-8",
        }}
      >
        {!user.role || user.role.length === 0 ? (
          <>
            <MenuItem component={Link} to="/sign-in" role="button">
              <ListItemIcon className="min-w-40">
                <FuseSvgIcon>heroicons-outline:lock-closed</FuseSvgIcon>
              </ListItemIcon>
              <ListItemText primary="Sign In" />
            </MenuItem>
            <MenuItem component={Link} to={`${import.meta.env.REACT_APP_URL}/sign-up`} role="button">
              <ListItemIcon className="min-w-40">
                <FuseSvgIcon>heroicons-outline:user-add </FuseSvgIcon>
              </ListItemIcon>
              <ListItemText primary="Sign up" />
            </MenuItem>
          </>
        ) : (
          <>
            <MenuItem
              component={Link}
              to="profile-settings"
              onClick={userMenuClose}
              role="button"
            >
              <ListItemIcon className="min-w-40">
                <FuseSvgIcon>heroicons-outline:user-circle</FuseSvgIcon>
              </ListItemIcon>
              <ListItemText primary="Profile settings" />
            </MenuItem>
            {user.role.includes("team-member") ? (
              <></>
            ) : (
              <>
                <MenuItem
                  component={Link}
                  to="subscription"
                  onClick={userMenuClose}
                  role="button"
                >
                  <ListItemIcon className="min-w-40">
                    <FuseSvgIcon>heroicons-outline:user-circle</FuseSvgIcon>
                  </ListItemIcon>
                  <ListItemText primary="Subscription & payments" />
                </MenuItem>
                <MenuItem
                  component={Link}
                  to="ref"
                  onClick={userMenuClose}
                  role="button"
                >
                  <ListItemIcon className="min-w-40">
                    <FuseSvgIcon>heroicons-outline:user-circle</FuseSvgIcon>
                  </ListItemIcon>
                  <ListItemText primary="Referrals Overview" />
                </MenuItem>
              </>
            )}
            <MenuItem
              onClick={() => {
                JwtService.logout();
              }}
            >
              <ListItemIcon className="min-w-40">
                <FuseSvgIcon>heroicons-outline:logout</FuseSvgIcon>
              </ListItemIcon>
              <ListItemText primary="Sign out" />
            </MenuItem>
          </>
        )}
      </Popover>
    </>
  );
}

export default UserMenu;
