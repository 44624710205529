import Hidden from "@mui/material/Hidden";
import { styled } from "@mui/material/styles";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { useDispatch, useSelector } from "react-redux";
import {
  navbarCloseMobile,
  selectFuseNavbar,
} from "app/store/fuse/navbarSlice";
import { selectFuseCurrentLayoutConfig } from "app/store/fuse/settingsSlice";
import NavbarStyle1Content from "./NavbarStyle1Content";
import React, { useRef, useState } from "react";

const navbarWidth = 161;
const navbarWidthCollapse = 48;

const StyledNavBar = styled("div")(({ theme, open, position }) => ({
  
  width: navbarWidthCollapse,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.easeOut,
    duration: theme.transitions.duration.leavingScreen,
  }),
  "& .logo-icon": {
    width: 24,
  },
  "&.hover": {
    boxShadow: "4px 0px 24px 0px #0A133552",
    width: navbarWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    "& .logo-icon": {
      width: "auto",
    },
  }
}));

const StyledNavBarMobile = styled(SwipeableDrawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    minWidth: navbarWidth,
    width: navbarWidth,
    maxWidth: navbarWidth,
  },
}));

function NavbarStyle1(props) {
  const dispatch = useDispatch();
  const config = useSelector(selectFuseCurrentLayoutConfig);
  const navbar = useSelector(selectFuseNavbar);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [closePopover, setClosePopover] = useState(0);
  const sideBarRef = useRef();

  const onMouseEnter = () => {
    setIsMenuOpen(true);
  };

  const onMouseLeave = (event) => {
    setClosePopover(closePopover + 1);
    setIsMenuOpen(false);
  };

  const onClosePopover = (evt) => {
    let sideBar = sideBarRef?.current;
    const x = evt.clientX,
      y = evt.clientY;

    if (sideBar) {
      const rect = sideBar.getBoundingClientRect();

      const mx1 = rect.x;
      const mx2 = rect.x + rect.width;
      const my1 = rect.y;
      const my2 = rect.y + rect.height;
      
      if (sideBar && x >= mx1 && x <= mx2 && y >= my1 && y <= my2) {
      } else {
        setIsMenuOpen(false);  
      }
    } else 
      setIsMenuOpen(false);
  };

  return (
    <>
      <Hidden lgDown>
        <StyledNavBar
          ref={sideBarRef}
          className={`flex-col flex-auto top-0 overflow-hidden h-screen shrink-0 z-20 border-r fixed ${isMenuOpen ? "hover" : ""}`}
          open={navbar.open}
          position={config.navbar.position}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <NavbarStyle1Content closePopover={closePopover} onClosePopover={onClosePopover} />
        </StyledNavBar>
      </Hidden>

      <Hidden lgUp>
        <StyledNavBarMobile
          classes={{
            paper: "flex-col flex-auto h-full",
          }}
          anchor={config.navbar.position}
          variant="temporary"
          open={navbar.mobileOpen}
          onClose={() => dispatch(navbarCloseMobile())}
          onOpen={() => {}}
          disableSwipeToOpen
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <NavbarStyle1Content />
        </StyledNavBarMobile>
      </Hidden>
    </>
  );
}

export default NavbarStyle1;
