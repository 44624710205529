import { lazy, Suspense } from "react";
import FuseLoading from "@fuse/core/FuseLoading/FuseLoading";
import { authRoles } from "app/auth";

const FAQPage = lazy(() => import("./FAQPage"));

const FAQPageConfig = {
  settings: {
    layout: {
      config: {
        toolbar: {
          display: false,
        },
      },
    },
  },
  auth: authRoles.all,
  routes: [
    {
      path: "/faq",
      element: (
        <Suspense fallback={<FuseLoading />}>
          <FAQPage />
        </Suspense>
      ),
    },
  ],
};

export default FAQPageConfig;

/*
Backend routes ==== Frontend routes
/user/confirm ==> https://afterlib.netlify.app/confirm-email
/user/forgot_password ==> https://afterlib.netlify.app/forgot-password
/user/reset_password ==> https://afterlib.netlify.app/reset-password/:token
*/
