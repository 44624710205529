import FuseUtils from "@fuse/utils/FuseUtils";
import axios from "axios";
import jwtDecode from "jwt-decode";
import jwtServiceConfig from "./jwtServiceConfig";
import { getCurrentPlan } from "../../plans";

/* eslint-disable camelcase */

class JwtService extends FuseUtils.EventEmitter {
  init() {
    setInterval(() => {
      this.signInWithToken();
    }, 10800000);
    this.setInterceptors();
    this.handleAuthentication();
  }

  setInterceptors = () => {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (err) => {
        return new Promise((resolve, reject) => {
          if (
            err.response.status === 401 &&
            err.config &&
            !err.config.__isRetryRequest
          ) {
            // if you ever get an unauthorized response, logout the user
            this.emit("onAutoLogout", "Invalid access_token");
            this.setSession(null);
          }
          throw err;
        });
      }
    );
  };

  handleAuthentication = () => {
    const access_token = this.getAccessToken();

    if (!access_token) {
      this.emit("onNoAccessToken");

      return;
    }

    if (this.isAuthTokenValid(access_token)) {
      this.setSession(access_token);
      this.emit("onAutoLogin", true);
    } else {
      this.setSession(null);
      this.emit("onAutoLogout", "access_token expired");
    }
  };

  createUser = (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(jwtServiceConfig.signUp, data)
        .then((response) => {
          if (response.data.success && response.status === 200) {
            resolve(response.data);
            this.emit("onLogin", response.data);
          } else {
            reject(response.data);
          }
        })
        .catch((_error) => {
          reject(_error?.response?.data);
        });
    });
  };

  confirmUser = (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(jwtServiceConfig.confirm, data)
        .then((response) => {
          if (response.data.success && response.status === 200) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        })
        .catch((_error) => {
          reject(_error?.response?.data);
        });
    });
  };

  resendConfirmation = (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(jwtServiceConfig.resendConfimation, data)
        .then((response) => {
          if (response.data.success) {
            resolve(response.data);
          }
        })
        .catch((_error) => {
          reject(_error?.response?.data);
        });
    });
  };

  checkPaymentStatus = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(jwtServiceConfig.checkPaymentStatus)
        .then((response) => {
          if (response.data.success) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        })
        .catch((_error) => {
          reject(_error?.response?.data);
        });
    });
  };

  signInWithEmailAndPassword = (email, password) => {
    return new Promise((resolve, reject) => {
      axios
        .post(jwtServiceConfig.signIn, {
          email,
          password,
        })
        .then((response) => {
          if (response.data.data && response.data.success) {
            const decoded_user = jwtDecode(response.data.data.auth_token);
            if (window.tidioChatApi) {
              window.tidioChatApi.setVisitorData({
                email: email,
              });
            }
            this.setSession(response.data.data.auth_token);
            let redirectUrl = "/subscription-packages";
            if (
              decoded_user.isActive ||
              decoded_user.freeAccess ||
              decoded_user.isAdmin
            ) {
              if (
                decoded_user.credits > 0 ||
                decoded_user.freeAccess ||
                decoded_user.isAdmin
              )
                redirectUrl = "/ads";
              else redirectUrl = "/subscription";
            }
            let user = {
              user: {
                user: decoded_user,
                loginRedirectUrl: redirectUrl,
              },
              role: decoded_user.isAdmin
                ? ["admin"]
                : decoded_user.isTeamMember
                ? ["team-member"]
                : (decoded_user.credits > 0 && decoded_user.isActive) ||
                  decoded_user.freeAccess
                ? ["subscriber"]
                : ["nocredits"],
              plan: getCurrentPlan(
                decoded_user.subModel,
                decoded_user.isAdmin,
                decoded_user.membership
              ),
              showTutorial: response.data.data.showTutorial,
            };
            resolve(response.data);
            this.emit("onLogin", user);
          } else {
            reject([response.data.message]);
          }
        })
        .catch((errors) => {
          reject(errors.response.data);
        });
    });
  };

  signInWithToken = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(jwtServiceConfig.refreshToken, {
          headers: {
            Authorization: `Bearer ${this.getAccessToken()}`,
          },
        })
        .then((response) => {
          if (response.data.success && response.status === 200) {
            const decoded_user = jwtDecode(response.data.data.auth_token);
            this.setSession(response.data.data.auth_token);
            let redirectUrl = "/subscription-packages";
            if (
              decoded_user.isActive ||
              decoded_user.freeAccess ||
              decoded_user.isAdmin
            ) {
              if (decoded_user.credits > 0) redirectUrl = "/ads";
              else redirectUrl = "/subscription";
            }
            let user = {
              user: {
                user: decoded_user,
                loginRedirectUrl: redirectUrl,
              },
              role: decoded_user.isAdmin
                ? ["admin"]
                : decoded_user.isTeamMember
                ? ["team-member"]
                : (decoded_user.credits > 0 && decoded_user.isActive) ||
                  decoded_user.freeAccess
                ? ["subscriber"]
                : ["nocredits"],
              plan: getCurrentPlan(
                decoded_user.subModel,
                decoded_user.isAdmin,
                decoded_user.membership
              ),
              showTutorial: response.data.data.showTutorial,
            };
            this.emit("Logged in with jwt", user);
            resolve(user);
          } else {
            this.logout();
            reject(new Error("Failed to login with token."));
          }
        })
        .catch((error) => {
          this.logout();
          reject(new Error("Failed to login with token."));
        });
    });
  };

  forgotPasswordAPI = (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(jwtServiceConfig.forgetPassword, data)
        .then((response) => {
          if (response.data.success && response.status === 200) {
            resolve(response.data);
          }
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  };

  resetPasswordAPI = (data, token) => {
    return new Promise((resolve, reject) => {
      axios
        .post(jwtServiceConfig.resetPassword, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (response.data.success && response.status === 200) {
            resolve(response.data);
          }
        })
        .catch((error) => {
          reject(error.response?.data);
        });
    });
  };

  updateUserData = (user) => {
    return axios.post(jwtServiceConfig.updateUser, {
      user,
    });
  };

  setSession = (access_token) => {
    if (access_token) {
      localStorage.setItem("jwt_access_token", access_token);
      axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
    } else {
      localStorage.removeItem("jwt_access_token");
      delete axios.defaults.headers.common.Authorization;
    }
  };

  logout = () => {
    this.setSession(null);
    this.emit("onLogout", "Logged out");
  };

  isAuthTokenValid = (access_token) => {
    if (!access_token) {
      return false;
    }
    const decoded = jwtDecode(access_token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      console.warn("access token expired");
      return false;
    }

    return true;
  };

  getAccessToken = () => {
    return window.localStorage.getItem("jwt_access_token");
  };

  confirmInvitation = (token) => {
    return new Promise((resolve, reject) => {
      axios
        .post(jwtServiceConfig.confirmInvitation, { token })
        .then((response) => {
          if (response.data.success && response.status === 200) {
            resolve(response.data);
          }
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  };

  createUserWithInvitation = (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(jwtServiceConfig.signUpWithInvitation, data)
        .then((response) => {
          if (response.data.success && response.status === 200) {
            resolve(response.data);
            this.emit("onLogin", response.data);
          } else {
            reject(response.data);
          }
        })
        .catch((_error) => {
          reject(_error?.response?.data);
        });
    });
  };

  addClicks = ({ link, unique }) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`/markPartner`, unique ? { link, unique } : { link })
        .then((response) => {
          if (response.data.success && response.status === 200) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        })
        .catch((_error) => {
          reject(_error?.response?.data);
        });
    });
  };
}

const instance = new JwtService();

export default instance;
