import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

const defaultSlice = createSlice({
  name: "defaultSlice",
  initialState: {
    prevSearches: [],
  },
  reducers: {
    updatePrevSearches: {
      reducer: (state, action) => {
        state.prevSearches = action.payload;
      },
    },
  },
  extraReducers: {},
});

export const { updatePrevSearches } = defaultSlice.actions;

export const selectPrevSearches = ({ defaultSlice }) =>
  defaultSlice?.prevSearches;

export default defaultSlice.reducer;
