// Internet Explorer 11 requires polyfills and partially supported by this project.
// import 'react-app-polyfill/ie11';
// import 'react-app-polyfill/stable';
import './i18n'
import './styles/app-base.css'
import './styles/app-components.css'
import './styles/app-utilities.css'
import { createRoot } from 'react-dom/client'
import App from './app/App'
import * as serviceWorker from './serviceWorker'
import reportWebVitals from './reportWebVitals'
// Date range files
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file

import * as Sentry from '@sentry/react'

Sentry.init({
  dsn: 'https://47bc96760312a3e170bd900be5746e94@o4506343135641600.ingest.sentry.io/4506435631054848',
  integrations: [
    new Sentry.BrowserTracing(),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

const container = document.getElementById('root')

const root = createRoot(container)
root.render(<App />)

reportWebVitals()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
