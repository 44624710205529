import React from "react";
import { Box, Typography } from "@mui/material";

const TourContent = ({ heading, content = [], ...props }) => {
  return (
    <Box>
      <Typography
        color="text.secondary"
        className="text-14 font-bold leading-5 mb-8"
      >
        {heading}
      </Typography>
      {content.map((p, key) => {
        const padding =
          content.length === 0 || key + 1 === content.length ? "pb-0" : "pb-6";
        return (
          <Typography
            key={key}
            color="text.secondary"
            className={`text-12 leading-4 font-medium ${padding}`}
          >
            {p}
          </Typography>
        );
      })}
    </Box>
  );
};

export default [
  {
    selector: "body",
    content: (props) => (
      <TourContent
        heading="Welcome to AfterLib"
        content={[
          `Here we’ll take you on our tour to understand main functionality of the AfterLib`,
        ]}
        {...props}
      />
    ),
  },
  {
    selector: ".first-step",
    disableActions: false,
    content: (props) => (
      <TourContent
        heading="Number of copies"
        content={[
          "The number in the green field indicates the active duplicate ads and can be an indicator of high ad spend.",
          `However, ads with few duplicates shouldn't be ignored, as a product with e.g. 15 ads running for a week can still be good.`,
          `Check the page performance to see if the advertiser is experienced, and use the store tracker if it's a Shopify shop. It's essential to use all information available to validate a product, rather than guessing or hoping. The goal is to test proven products.`,
        ]}
        {...props}
      />
    ),
    stepInteraction: true,
  },
  {
    selector: ".second-step",
    disableActions: false,
    content: (props) => (
      <TourContent
        heading="Trend factor"
        content={[
          `The number beside the rocket icon indicates the ad's trend score, which is determined by its start date, the number of active ads, and the ad quantity progression. This allows you to discover promising products before most advertisers using traditional spy tools.`,
        ]}
        {...props}
      />
    ),
    stepInteraction: true,
  },
  {
    selector: ".third-step",
    content: (props) => (
      <TourContent
        heading="Performance factor"
        content={[
          `The number beside the performance sign displays the ad's performance score, which is influenced by its duration, the number of active ads, and the ad quantity progression. The duration of the ad is the most significant factor in determining its performance.`,
        ]}
        {...props}
      />
    ),
    stepInteraction: true,
  },
  {
    selector: ".fourth-step",
    content: (props) => (
      <TourContent
        heading="Current Progression"
        content={[
          `The progression is the percentage change in the active duplicate ads, indicating increased investment. Check the ad quantity ratio from the launch to the actual date. Deactivated duplicates turn the percentage red, but it doesn't necessarily mean the product isn't performing well. It can simply be that unprofitable ads are deactivated.`,
          `Click the percentage to see the ad quantity history. If there's no or little progression after some time, it doesn't mean the product isn't good. The advertiser might not be duplicating the ads or only doing so slightly, adjusting only the existing ad sets' budget.`,
        ]}
        {...props}
      />
    ),
  },
  {
    selector: ".fifth-step",
    content: (props) => (
      <TourContent
        heading="Display name"
        content={[
          `Hovering your mouse over the page name displays useful information such as whether the page/brand has a community, its start date, and the number of unique/active ads. This can be an indicator of profitable ads of high-performing pages.`,
        ]}
        {...props}
      />
    ),
  },
  {
    selector: ".sixth-step",
    content: (props) => (
      <TourContent
        heading="More options"
        content={[
          `Clicking on this field gives you several options, which are self-explanatory. Adding ads or pages to your favorites allows you to find them in the related section by clicking on the heart in the top right corner`,
        ]}
        {...props}
      />
    ),
  },
  // {
  //   selector: ".seventh-step",
  //   content: (props) => (
  //     <TourContent
  //       heading="Active Ads"
  //       content={[
  //         `Specify the minimum or maximum number of active duplicates an ad should have. It is recommended to set a minimum of 4 and a maximum of 70 active ads. With practice, you will get better at finding proven products using the software.`,
  //       ]}
  //       {...props}
  //     />
  //   ),
  // },
];
