import i18next from "i18next";
import ar from "./navigation-i18n/ar";
import en from "./navigation-i18n/en";
import tr from "./navigation-i18n/tr";
import {
  AdminPanelIcon,
  AdsIcon,
  PagesIcon,
  StoreIcon,
  TutorialIcon,
} from "./NavIcons";

i18next.addResourceBundle("en", "navigation", en);
i18next.addResourceBundle("tr", "navigation", tr);
i18next.addResourceBundle("ar", "navigation", ar);

const navigationConfig = [
  {
    id: "ads-component",
    title: "Ads Library",
    type: "item",
    icon: "heroicons-outline:star",
    Icon: AdsIcon,
    url: "ads",
  },
  {
    id: "pages-component",
    title: "Pages Library",
    type: "item",
    icon: "heroicons-outline:star",
    Icon: PagesIcon,
    url: "pages",
  },
  // {
  //   id: "store-component",
  //   title: "Stores Library",
  //   type: "item",
  //   icon: "heroicons-outline:star",
  //   Icon: StoreIcon,
  //   url: "store/store-list",
  // },
  {
    id: "tutorial-component",
    title: "Guides",
    type: "item",
    icon: "heroicons-outline:star",
    Icon: TutorialIcon,
    url: "tutorials",
  },
  // {
  //   id: "admin-component",
  //   title: "Admin Panel",
  //   type: "item",
  //   icon: "heroicons-outline:star",
  //   Icon: AdminPanelIcon,
  //   url: "admin-panel",
  // },
];

export default navigationConfig;
