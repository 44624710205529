import React, { useState, memo, useEffect } from "react";
import FuseScrollbars from "@fuse/core/FuseScrollbars";
import { styled, useTheme } from "@mui/material/styles";
import styledComponent from "styled-components";
import clsx from "clsx";
import Logo from "../../../../shared-components/Logo";
import { useTour } from "@reactour/tour";
// import NavbarToggleButton from "../../../../shared-components/NavbarToggleButton";
// import UserNavbarHeader from "../../../../shared-components/UserNavbarHeader";
import Navigation from "../../../../shared-components/Navigation";
import { selectMainTheme } from "app/store/fuse/settingsSlice";
import themesConfig from "app/configs/themesConfig";
import {
  Avatar,
  Divider,
  List,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Typography,
  ListItem,
  Box,
  Button,
} from "@mui/material";
import { HelpIcon, ModeIcon, TwitterIcon } from "./icons";
import { CustomCheckbox } from "app/shared-components/CustomCheckbox";
import { useDispatch, useSelector } from "react-redux";
import { changeFuseTheme } from "app/store/fuse/settingsSlice";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { selectUser, selectUserProps } from "app/store/userSlice";
import { Link, useNavigate } from "react-router-dom";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import JwtService from "app/auth/services/jwtService";
import { useFinishTourMutation } from "../../../../../ReactTour/api";
import { openTidio } from "app/utils/index";
import {
  HelpCenterIcon,
  SettingIcon,
  SocialIcon,
  SocialLinkIcon,
  SocialSiteIcon,
  ArrowForwardIcon,
} from "app/shared-components/svgs";

const StyledIconWrapper = styled(Box)(({ theme }) => ({
  svg: {
    fill: "none",
    width: "18px",
  },
  "svg path": {
    stroke: "#3E3E66",
  },
}));

const Root = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  "& ::-webkit-scrollbar-thumb": {
    boxShadow: `inset 0 0 0 20px ${
      theme.palette.mode === "light"
        ? "rgba(0, 0, 0, 0.24)"
        : "rgba(255, 255, 255, 0.24)"
    }`,
  },
  "& ::-webkit-scrollbar-thumb:active": {
    boxShadow: `inset 0 0 0 20px ${
      theme.palette.mode === "light"
        ? "rgba(0, 0, 0, 0.37)"
        : "rgba(255, 255, 255, 0.37)"
    }`,
  },
  "& li.subscribe": {
    display: "none",
  },
  ".hover & li.subscribe": {
    display: "block !important",
  },
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  "&:hover": {
    backgroundColor: theme.palette.background.default,
  },
}));

const StyledContent = styled(FuseScrollbars)(({ theme }) => ({
  overscrollBehavior: "contain",
  overflowX: "hidden",
  overflowY: "auto",
  WebkitOverflowScrolling: "touch",
  backgroundRepeat: "no-repeat",
  backgroundSize: "100% 40px, 100% 10px",
  backgroundAttachment: "local, scroll",
}));

const StyledPopover = styled(Popover)(({ theme }) => ({
  zIndex: 1500,
  "& .MuiPopover-root": {
    width: "fit-content",
  },
  "& .MuiPaper-root": {
    padding: "10px",
    minWidth: "310px",
    maxWidth: "310px",
  },
  "& .caption": {
    color: "#AAA",
  },
  "& .menu-item-hover": {
    marginLeft: -8,
    marginRight: -8,
    paddingLeft: 8,
    paddingRight: 8,
  },
  "& .menu-item-hover.hide-item": {
    marginLeft: 0,
    marginRight: 0,
    paddingLeft: 10,
    paddingRight: 10,
  },
  "& .menu-item-hover:hover": {
    background: theme.palette.text.primary_10,
  },
}));

function NavbarStyle1Content(props) {
  const { isOpen } = useTour();
  const [finishTour, { isLoading }] = useFinishTourMutation();
  const currentTheme = useSelector(selectMainTheme);
  const userProps = useSelector(selectUserProps);
  const user = useSelector(selectUser);
  const darkTheme = themesConfig["defaultDark"];
  const lightTheme = themesConfig["default"];
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();

  const [userMenu, setUserMenu] = useState(null);

  const userMenuClick = (event) => {
    setUserMenu(event.currentTarget);
  };

  const userMenuClose = (evt) => {
    setUserMenu(null);
    props.onClosePopover && props.onClosePopover(evt);
  };

  // load default theme from local storage
  const afterLibTheme = window.localStorage.getItem("afterlib-theme");
  useEffect(() => {
    dispatch(
      changeFuseTheme(afterLibTheme === "dark" ? darkTheme : lightTheme)
    );
  }, [afterLibTheme]);

  useEffect(() => {
    setUserMenu(null);
  }, [props.closePopover]);

  const handleThemeSwitcher = (evt) => {
    evt.stopPropagation();

    const currentThemeMode = currentTheme.palette.mode;
    window.localStorage.setItem(
      "afterlib-theme",
      currentThemeMode === "dark" ? "light" : "dark"
    );
    dispatch(
      changeFuseTheme(currentThemeMode === "dark" ? lightTheme : darkTheme)
    );
  };

  return (
    <Root
      className={clsx(
        "flex flex-auto flex-col overflow-hidden h-full",
        props.className
      )}
    >
      <div className="flex flex-row items-center h-48 shrink-0 md:h-48 border-b-1 px-14">
        <div className="flex justify-center flex-1">
          <Logo />
        </div>
      </div>

      <StyledContent
        className="flex flex-col flex-1 min-h-0"
        option={{ suppressScrollX: true, wheelPropagation: false }}
      >
        <Navigation layout="vertical" className="!p-0" />
      </StyledContent>

      <BottomMenu style={{ paddingBottom: 0 }}>
        <StyledListItem className="items-center justify-center p-8 subscribe">
          <Box className="items-center justify-center border p-8">
            <Box className="flex items-center justify-center text-16">
              Subscribe with
            </Box>
            <Box className="flex items-center justify-center text-16 mb-8">
              20% discount
            </Box>
            <Button
              color="cta"
              variant="contained"
              fullWidth
              endIcon={<ArrowForwardIcon />}
            >
              Continue
            </Button>
          </Box>
        </StyledListItem>
        <Divider />
        <StyledListItem
          className="flex items-center h-48 px-16 py-10 space-x-12 bottom-menu-item"
          onClick={() => {
            window.open(
              "https://x.com/afterlib",
              "_blank",
              "noreferrer,nofollow,noopener"
            );
          }}
        >
          <Box>
            <SocialSiteIcon />
          </Box>
          <Typography variant="primary" className="flex-1 text-14 font-medium">
            Social
          </Typography>
        </StyledListItem>
        <Divider />
        <StyledListItem
          className="flex items-center h-48 px-16 py-10 space-x-12 bottom-menu-item"
          onClick={openTidio}
        >
          <Box>
            <HelpCenterIcon />
          </Box>
          <Box className="flex flex-1">
            <Typography
              variant="subtitle2"
              className="flex-1 text-14 font-medium"
            >
              <nobr>Help center</nobr>
            </Typography>
          </Box>
        </StyledListItem>
        <Divider />
        <StyledListItem
          className="flex items-center h-48 px-16 py-10 space-x-12 bottom-menu-item"
          onClick={userMenuClick}
        >
          <Box>
            <SettingIcon />
          </Box>
          <Typography
            variant="subtitle2"
            className="flex-1 text-14 font-medium"
          >
            <nobr>Your settings</nobr>
          </Typography>
        </StyledListItem>
        <Popover
          open={Boolean(userMenu)}
          anchorEl={userMenu}
          onClose={userMenuClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          classes={{
            paper: "py-4",
          }}
          PaperProps={{
            style: { width: 280 },
          }}
          style={{ marginTop: 13 }}
        >
          <MenuItem
            component={Link}
            to="profile-settings"
            onClick={userMenuClose}
            role="button"
          >
            <ListItemText
              primary={
                <Typography className="text-14 font-medium">
                  Profile settings
                </Typography>
              }
            />
          </MenuItem>
          {user.role.includes("team-member") ? (
            <></>
          ) : (
            <>
              <MenuItem
                component={Link}
                to="subscription"
                onClick={userMenuClose}
                role="button"
              >
                <ListItemText
                  primary={
                    <Typography className="text-14 font-medium">
                      Plan settings
                    </Typography>
                  }
                />
              </MenuItem>
              <MenuItem
                component={Link}
                to="ref"
                onClick={userMenuClose}
                role="button"
              >
                <ListItemText
                  primary={
                    <Typography className="text-14 font-medium">
                      Affiliate program
                    </Typography>
                  }
                />
              </MenuItem>
            </>
          )}
          <MenuItem role="button">
            <Box className="flex w-full">
              <ListItemText
                primary={
                  <Typography className="text-14 font-medium flex-1">
                    Dark mode
                  </Typography>
                }
              />
              <Box style={{ marginTop: -12, marginRight: -12, height: 24 }}>
                <CustomCheckbox
                  className="custom-check"
                  checked={currentTheme.palette.mode === "dark"}
                  value={currentTheme.palette.mode === "dark"}
                  onChange={handleThemeSwitcher}
                />
              </Box>
            </Box>
          </MenuItem>
          <Divider style={{ marginTop: 4, marginBottom: 4 }} />
          <MenuItem
            onClick={() => {
              JwtService.logout();
            }}
          >
            <ListItemText
              disableTypography
              primary={
                <Typography
                  variant="body2"
                  style={{ color: theme.palette.text.disabled }}
                >
                  Log out
                </Typography>
              }
            />
          </MenuItem>
        </Popover>
      </BottomMenu>
      {/* <NavbarToggleButton className="w-40 h-40 p-0" /> */}
    </Root>
  );
}

export default memo(NavbarStyle1Content);

const BottomMenu = styledComponent(List)`
.bottom-menu-item{
  cursor: pointer;
}
`;
