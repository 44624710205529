import FuseUtils from "@fuse/utils";
import FuseLoading from "@fuse/core/FuseLoading";
import { Navigate } from "react-router-dom";
import settingsConfig from "app/configs/settingsConfig";
import HomeConfig from "../main/home/HomeConfig";
import TermsConfig from "../main/terms/TermsConfig";
import Error404Page from "../main/404/Error404Page";
import FAQPageConfig from "../main/FAQ/FAQConfig";
import RefLinkConfig from "../main/ref-link/RefLinkConfig";
import NormalRefLinkConfig from "../main/normal-ref-link/RefLinkConfig";

const routeConfigs = [
  HomeConfig,
  TermsConfig,
  FAQPageConfig,
  RefLinkConfig,
  NormalRefLinkConfig,
];

const routes = [
  ...FuseUtils.generateRoutesFromConfigs(
    routeConfigs,
    settingsConfig.defaultAuth
  ),
  {
    path: "/",
    element: <Navigate to="/subscription-packages" />,
  },
  {
    path: "loading",
    element: <FuseLoading />,
  },
  {
    path: "404",
    element: <Error404Page />,
  },
  {
    path: "*",
    element: <Navigate to="404" />,
  },
];

export default routes;
