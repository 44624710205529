import List from "@mui/material/List";
import { styled } from "@mui/material/styles";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import FuseNavItem from "../FuseNavItem";

export const StyledList = styled(List)(({ theme }) => ({
  "& .fuse-list-item": {
    height: 48,
    fontWeight: 900,
    borderBottom: "1px solid " + theme.palette.text.primary_5,
    borderRadius: 0,
    marginBottom: 0,
    paddingLeft: 14,
    color: theme.palette.text.primary,
    "&:hover": {
      background: "var(--main-light, " + theme.palette.background.default + ") !important",
      color: theme.palette.text.secondary,
    },
    "&:focus:not(.active)": {
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255, 255, 255, 0.06)"
          : "rgba(0,0,0,.05)",
    },
  },
  "& .fuse-list-item-text": {
    margin: 0,
  },
  "& .fuse-list-item-text-primary": {
    lineHeight: "20px",
    fontSize: "1.4rem",
  },
  "&.active-square-list": {
    "& .fuse-list-item, & .active.fuse-list-item": {
      width: "100%",
      borderRadius: "0",
    },
  },
  "&.dense": {
    "& .fuse-list-item": {
      paddingTop: 0,
      paddingBottom: 0,
      height: 32,
    },
  },
}));

function FuseNavVerticalLayout1(props) {
  const { navigation, active, dense, className, onItemClick } = props;

  function handleItemClick(item) {
    onItemClick?.(item);
  }

  return (
    <StyledList
      className={clsx(
        "navigation whitespace-nowrap py-0",
        `active-${active}-list`,
        dense && "dense",
        className
      )}
    >
      {navigation.map((_item) => (
        <FuseNavItem
          key={_item.id}
          type={`vertical-${_item.type}`}
          item={_item}
          nestedLevel={0}
          onItemClick={handleItemClick}
        />
      ))}
    </StyledList>
  );
}

export default FuseNavVerticalLayout1;
