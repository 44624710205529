export const lightPaletteText = {
  primary: "#3E3E66",
  secondary: "#12121F",
  success: "#ACEECA",
  success_0: '#15B725',
  disabled: "#B4B4CC",
  primary_30: "#9696B2",
  primary_20: '#9F9FCB',
  primary_10: "#DCDCE5",
  primary_5: "#ECEFF5",
  primary_0: "#FFFFFF",
  primary_rgb: "rgb(62,62,102)",
};

export const darkPaletteText = {
  primary: "#EBEBF7", //"#9F9FCB",
  secondary: "#EBEBF7",
  success: "#28B568",
  disabled: "#B4B4CC",
  primary_30: "#EBEBF7", //"#4A4A66"
  primary_20: "#4A4A66",
  primary_10: "#232335",
  primary_5: "#1D1D26",
  primary_0: "#111114",
  primary_rgb: "rgb(235,235,247)",
};

const typography = {
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
};

const themesConfig = {
  default: {
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            "&:hover, &:focus": {
              boxShadow: "0px 4px 5px 0px rgba(10, 19, 53, 0.08)",
              backgroundColor: "#ffffff",
            },
          },
          contained: {
            "&:hover, &:focus": {
              boxShadow: "0px 4px 5px rgba(10, 19, 53, 0.08)",
            },
          },
          outlinedSizeMedium: {
            border: "1px solid #DCDCE6",
            background: "#FFFFFF",
            "&:hover, &:focus": {
              border: "1px solid #DCDCE6",
              boxShadow: "0px 4px 5px rgba(10, 19, 53, 0.08)",
            },
          },
        },
      },
    },
    palette: {
      mode: "light",
      divider: "#DCDCE6",
      text: lightPaletteText,
      common: {
        black: "rgb(17, 24, 39)",
        white: "rgb(255, 255, 255)",
      },
      primary: {
        light: "#ECEFF5",
        dark: "#ECEFF5",
        main: "#ECEFF5",
        contrastText: lightPaletteText.primary,
      },
      secondary: {
        light: "#FFFFFF",
        main: "#FFFFFF",
        dark: "#FFFFFF",
        contrastText: lightPaletteText.primary,
      },
      outlined: {
        light: "#FFFFFF",
        main: "#FFFFFF",
        dark: "#FFFFFF",
        contrastText: lightPaletteText.primary,
        brChart: "#DDDDDD",
      },
      cta: {
        light: "#ACEECA",
        main: "#50CD89",
        dark: "#28B568",
        contrastText: lightPaletteText.secondary,
      },
      background: {
        paper: "#FFFFFF",
        default: "#F5F5FA",
        bg_30: "#9696B2",
        bg_10: "#DCDCE5",
        bg_5: "#ECEFF5",
        bg_0: "#FFFFFF",
        bg_history_dec: "#FDE6E6",
        bg_history_inc: "#E6FDF1",
        bg_chart: "#FFFFFF",
      },
      error: {
        light: "#ffcdd2",
        main: "#D12626",
        dark: "#b71c1c",
      },
      success: {
        main: "#15B725",
        dark: "#099918",
        light: "#4adb58",
      },
    },
    typography,
    status: {
      danger: "orange",
    },
  },
  defaultDark: {
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            "&:hover, &:focus": {
              boxShadow: "0px 4px 5px 0px rgb(12 12 12)",
              backgroundColor: "#232335",
            },
          },
          contained: {
            "&:hover, &:focus": {
              boxShadow: "0px 4px 5px 0px rgb(12 12 12)",
            },
          },
          outlinedSizeMedium: {
            border: "1px solid #232335",
            background: "#111114",
            "&:hover, &:focus": {
              border: "1px solid #232335",
              boxShadow: "0px 4px 5px 0px rgb(12 12 12)",
            },
          },
        },
      },
    },
    palette: {
      mode: "dark",
      divider: "#1D1D26",
      text: darkPaletteText,
      common: {
        black: "rgb(17, 24, 39)",
        white: "rgb(255, 255, 255)",
      },
      primary: {
        light: "#1D1D26",
        dark: "#1D1D26",
        main: "#1D1D26",
        contrastText: darkPaletteText.primary, // lightPaletteText.primary,
      },
      secondary: {
        light: "#111114",
        main: "#111114",
        dark: "#111114",
        contrastText: darkPaletteText.primary, // lightPaletteText.primary,
      },
      outlined: {
        light: "#111114",
        main: "#111114",
        dark: "#111114",
        contrastText: lightPaletteText.primary,
        brChart: "transparent",
      },
      cta: {
        light: "#ACEECA",
        main: "#50CD89",
        dark: "#28B568",
        contrastText: lightPaletteText.secondary,
      },
      background: {
        paper: "#111114",
        default: "#18191C",
        bg_30: "#4A4A66",
        bg_10: "#232335",
        bg_5: "#1D1D26",
        bg_0: "#000000",
        bg_history_dec: "#480000",
        bg_history_inc: "#003519",
        bg_chart: "transparent",
      },
      error: {
        light: "#ffcdd2",
        main: "#D12626",
        dark: "#b71c1c",
      },
      success: {
        main: "#15B725",
        dark: "#099918",
        light: "#4adb58",
      },
    },
    typography,
    status: {
      danger: "orange",
    },
  },
};

export default themesConfig;
