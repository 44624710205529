import React, { useEffect } from "react";
import { styled, Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import {
  closeTopbarNotification,
  selectTopbarNotificationState,
} from "app/store/fuse/topbarNotificationSlice";

const StyledNotificationBar = styled(Box)(
  ({ theme, variant, show, show_close_icon }) => ({
    minHeight: "20px",
    width: "100%",
    padding: "4px 12px",
    display: show === "true" ? "flex" : "none",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "14px",
    fontWeight: 500,
    textAlign: "center",
    background: theme.palette.background.bg_30,
    color: theme.palette.text.secondary,
    transition: "all ease 0.4s",
    span: {
      width: "80%",
    },
    position: "relative",
    "& .close-btn": {
      display: show_close_icon === "true" ? "block" : "none",
      position: "absolute",
      right: "16px",
    },
    ...(variant === "success" && {
      background: theme.palette.success.main,
      color: theme.palette.text.secondary,
    }),
    ...(variant === "error" && {
      background: theme.palette.error.main,
      color: theme.palette.text.secondary,
    }),
    ...(variant === "warning" && {
      background: "#c7a007",
      color: theme.palette.text.secondary,
    }),
  })
);

export default function TopbarNotification() {
  const dispatch = useDispatch();
  const topNotificationState = useSelector(selectTopbarNotificationState);
  const { show, message, variant, autoClose, autoCloseDuration } =
    topNotificationState;

  useEffect(() => {
    if (show && autoClose) {
      setTimeout(() => {
        dispatch(closeTopbarNotification());
      }, autoCloseDuration);
    }
  }, [topNotificationState]);

  return (
    <StyledNotificationBar
      show={show ? "true" : "false"}
      show_close_icon={!autoClose ? "true" : "false"}
      variant={variant}
    >
      <span>{message}</span>
      <IconButton
        className="close-btn -mt-6"
        onClick={() => dispatch(closeTopbarNotification())}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </StyledNotificationBar>
  );
}
