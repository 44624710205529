import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  show: false,
  message: "Important Notice: AfterLib is working to improve quality and speed for your ad collection. Remember, this isn't the final version. We appreciate your patience and will compensate for any errors that occurred in the last few days. More updates are on the way!",
  variant: "success", // info | success | error | warning
  autoClose: true,
  autoCloseDuration: 3000,
};

const topBarNotificationSlice = createSlice({
  name: "top-bar-notification-slice",
  initialState: initialState,
  reducers: {
    openTopbarNotification: (state, { payload }) => {
      state = { ...state, ...payload, show: true };
      return state;
    },
    closeTopbarNotification: (state) => {
      state = initialState;
      return state;
    },
  },
});

export const { openTopbarNotification, closeTopbarNotification } =
  topBarNotificationSlice.actions;
export const selectTopbarNotificationState = ({ fuse }) =>
  fuse.topbarNotificationState;

export default topBarNotificationSlice.reducer;
