import { combineReducers } from "@reduxjs/toolkit";
import dialog from "./dialogSlice";
import message from "./messageSlice";
import navbar from "./navbarSlice";
import navigation from "./navigationSlice";
import settings from "./settingsSlice";
import breadcrumbs from "./breadcrumbsSlice";
import topbarNotificationState from "./topbarNotificationSlice";
import bottomNotificationState from "./bottomNotificationSlice";

const fuseReducers = combineReducers({
  navigation,
  settings,
  navbar,
  message,
  dialog,
  breadcrumbs,
  topbarNotificationState,
  bottomNotificationState,
});

export default fuseReducers;
