import { Box } from "@mui/material";
import { selectMainTheme } from "app/store/fuse/settingsSlice";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export default function AfterlibLogo(props) {
  const [src, setSrc] = useState("/assets/afterlib/images/logo/logo-light.svg");
  const { palette } = useSelector(selectMainTheme);

  useEffect(() => {
    setSrc(
      palette.mode === "light"
        ? "/assets/afterlib/images/logo/logo-light.svg"
        : "/assets/afterlib/images/logo/logo-dark.png"
    );
  }, [palette]);

  return (
    <Box {...props}>
      <img src={src} alt="afterlib-logo" style={{maxWidth: "none", maxHeight: "100%"}} />
    </Box>
  );
}
