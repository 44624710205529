/**
 * Authorization Roles
 * This roles array represent to pages
 */
const authRoles = {
  admin: ["admin"],
  user: ["user", "subscriber"],
  subscriber: ["admin", "subscriber"],
  teamMember: ["team-member"],
  all: ["admin", "subscriber", "user", "team-member"],
  onlyGuest: [],
};

export default authRoles;
