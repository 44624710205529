import React from "react";

export const AdsIcon = ({ stroke }) => {
  return (
    <svg
      width="17"
      height="20"
      viewBox="0 0 17 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.3333 5H6.83333C5.72876 5 4.83333 5.89543 4.83333 7V15M12.3333 5H13.6667C14.7712 5 15.6667 5.89543 15.6667 7V16.3333C15.6667 17.4379 14.7712 18.3333 13.6667 18.3333H6.83333C5.72876 18.3333 4.83333 17.4379 4.83333 16.3333V15M12.3333 5V3.66667C12.3333 2.5621 11.4379 1.66667 10.3333 1.66667H3.5C2.39543 1.66667 1.5 2.5621 1.5 3.66667V13C1.5 14.1046 2.39543 15 3.5 15H4.83333"
        stroke={stroke || "#3E3E66"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const PagesIcon = ({ size, stroke, ...props }) => {
  return (
    <svg
      {...props}
      width={size || "16"}
      height={size || "16"}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.33333 5.5L14.6667 5.5M8 14.6667V5.5M14.6667 13V3C14.6667 2.07953 13.9205 1.33334 13 1.33334L3 1.33334C2.07952 1.33334 1.33333 2.07953 1.33333 3L1.33333 13C1.33333 13.9205 2.07952 14.6667 3 14.6667H13C13.9205 14.6667 14.6667 13.9205 14.6667 13Z"
        stroke={stroke || "#3E3E66"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const StoreIcon = () => {
  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.6667 7.68426V12.6667C15.6667 13.7712 14.7712 14.6667 13.6667 14.6667H4.33333C3.22876 14.6667 2.33333 13.7712 2.33333 12.6667V7.68426M5.25 6.12499C5.25 7.16053 4.41053 7.99999 3.375 7.99999C2.42627 7.99999 1.64212 7.29537 1.51732 6.38091C1.49428 6.21212 1.52176 6.04156 1.56477 5.87673L2.35996 2.82848C2.5897 1.94782 3.38506 1.33333 4.29519 1.33333H13.7048C14.6149 1.33333 15.4103 1.94782 15.64 2.82848L16.4352 5.87673C16.4782 6.04156 16.5057 6.21212 16.4827 6.38091C16.3579 7.29537 15.5737 7.99999 14.625 7.99999C13.5895 7.99999 12.75 7.16053 12.75 6.12499M5.25 6.12499C5.25 7.16053 6.08947 7.99999 7.125 7.99999C8.16053 7.99999 9 7.16053 9 6.12499M5.25 6.12499L5.66667 1.33333M9 6.12499C9 7.16053 9.83947 7.99999 10.875 7.99999C11.9105 7.99999 12.75 7.16053 12.75 6.12499M9 6.12499V1.33333M12.75 6.12499L12.3333 1.33333"
        stroke="#595980"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const TutorialIcon = () => {
  return (
    <svg
      width="19"
      height="16"
      viewBox="0 0 19 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5 5.33334L9 1.16667L1.5 5.33334L4 6.72223M16.5 5.33334L14 6.72223M16.5 5.33334V5.33334C17.0107 5.5887 17.3333 6.1107 17.3333 6.6817V11.1665M4 6.72223L9 9.50001L14 6.72223M4 6.72223V11.7222L9 14.5L14 11.7222L14 6.72223"
        stroke="#3E3E66"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const AdminPanelIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.33337 5.33331C3.33337 4.22874 4.2288 3.33331 5.33337 3.33331H14.6667C15.7713 3.33331 16.6667 4.22874 16.6667 5.33331V14.6666C16.6667 15.7712 15.7713 16.6666 14.6667 16.6666H5.33337C4.2288 16.6666 3.33337 15.7712 3.33337 14.6666V5.33331Z"
        stroke="#3E3E66"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.5423 6.87477C9.71742 6.47635 10.2827 6.47635 10.4578 6.87477L11.0381 8.19518C11.1124 8.36425 11.2739 8.47859 11.458 8.49256L12.9128 8.60293C13.3639 8.63715 13.5413 9.20506 13.1899 9.48991L12.1231 10.3547C11.9718 10.4773 11.9055 10.6765 11.9531 10.8653L12.286 12.1862C12.3941 12.6148 11.9337 12.9625 11.551 12.7413L10.2503 11.9895C10.0954 11.9 9.90464 11.9 9.74983 11.9895L8.44909 12.7413C8.06642 12.9625 7.60601 12.6148 7.71404 12.1862L8.04701 10.8653C8.09459 10.6765 8.02827 10.4773 7.87703 10.3547L6.81023 9.48991C6.45882 9.20506 6.6362 8.63715 7.08726 8.60293L8.54204 8.49256C8.72619 8.47859 8.88765 8.36425 8.96196 8.19518L9.5423 6.87477Z"
        fill="#3E3E66"
      />
    </svg>
  );
};
