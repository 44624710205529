import { createApi } from "@reduxjs/toolkit/query/react";
import customFetchBaseQuery from "app/store/customFetchBaseQuery";

export const TourAPI = createApi({
  reducerPath: "TourAPI",
  tagTypes: ["getTutorial"],
  baseQuery: customFetchBaseQuery,
  endpoints: (builder) => ({
    finishTour: builder.mutation({
      query: (tourValue) => {
        return {
          url: "/show_tutorial",
          method: "POST",
          body: {
            showTutorial: tourValue,
          },
        };
      },
      invalidatesTags: ["getTutorial"],
    }),
    getTourValue: builder.query({
      query: () => {
        return {
          url: "/show_tutorial",
          method: "GET",
        };
      },
      transformResponse: (res) => res.data?.showTutorial,
      providesTags: ["getTutorial"],
    }),
  }),
});

export const { useFinishTourMutation, useGetTourValueQuery } = TourAPI;

export default TourAPI;
