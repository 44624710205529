import { useState, useEffect } from "react";
import { TourProvider } from "@reactour/tour";
import { Button, Typography, styled } from "@mui/material";
import { useFinishTourMutation } from "./api";
import steps from "./steps";
import "./reactour.css";

const StyledTourProvider = styled(TourProvider)(({ theme, transform, transition }) => ({
  backgroundColor: `${theme.palette.background.paper} !important`,
  borderRadius: "8px",
  marginTop: 16,
  marginBottom: 16,
  transform,
  transition
}));

export default function ReactTour({ props, children }) {
  const afterLibTheme =
    window.localStorage.getItem("afterlib-theme") || "light";
  const [isFirstStep, setIsFirstStep] = useState(true);
  const [transform, setTransform] = useState(
    "translate(-500px, -500px)!important"
  );
  const [transition, setTransition] = useState("none!important")
  const [maskClass, setMaskClass] = useState("initial-mask");
  const [finishTour] = useFinishTourMutation();
  
  const handleNext = async ({ setCurrentStep, currentStep, stepsLength }) => {
    setTransition("none")
    setIsFirstStep(currentStep === 0);
    if (currentStep < stepsLength - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      await finishTour(false)
      setTimeout(() => {
        setCurrentStep(0)
      }, 2000)
    }
  };

  const handleBack = ({ setCurrentStep, currentStep }) => {
    setIsFirstStep(currentStep === 1);
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleAfterOpen = () => {
    setTransform("none");
    setIsFirstStep(true);
    setMaskClass(
      afterLibTheme === "light" ? "light-theme-mask" : "dark-theme-mask"
    );
  };

  const handleBeforeClose = () => {
    finishTour(false);
    setTransform("translate(-500px, -500px)!important");
    setMaskClass("initial-mask");
    setTransition("none!important")
  };

  return (
    <StyledTourProvider
      transform={transform}
      transition={transition}
      steps={steps}
      showCloseButton={true}
      showDots={false}
      showBadge={false}
      disableInteraction={true}
      afterOpen={handleAfterOpen}
      beforeClose={handleBeforeClose}
      onClickMask={() => {}}
      highlightedMaskClassName={
        isFirstStep ? `tour_first_step` : `tour_heightlight`
      }
      maskClassName={maskClass}
      prevButton={(prevProps) => {
        if (prevProps.currentStep === 0) {
          return <div></div>;
        }
        return (
          <>
            {prevProps.currentStep !== 0 && (
              <Button
                color="secondary"
                variant="outlined"
                onClick={() => handleBack(prevProps)}
              >
                Back
              </Button>
            )}
            <Typography
              color="text.primary_30"
              className="text-14 leading-5 font-normal"
            >
              {prevProps.currentStep + 1}/{prevProps.stepsLength}
            </Typography>
          </>
        );
      }}
      nextButton={(nextProps) => {
        const buttonText =
          nextProps.currentStep === 0
            ? "Let's go"
            : nextProps.currentStep === steps.length - 1
            ? "Finish"
            : "Next";
        return (
          <>
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => handleNext(nextProps)}
            >
              {buttonText}
            </Button>
          </>
        );
      }}
    >
      <>{children}</>
    </StyledTourProvider>
  );
}
