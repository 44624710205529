import jwtDecode from "jwt-decode";
import moment from "moment";
import {
  format,
  addDays,
} from "date-fns";

export const substring = (str = "", limit = 50, disable = false) => {
  if (disable) {
    return str;
  }
  const isLimitExceeded = str.length > limit;
  if (isLimitExceeded) {
    const newStr = str.substring(0, 50);
    return `${newStr}...`;
  }
  return str;
};

export function getTimeAgo(date) {
  const now = moment();
  const then = moment(date);

  const duration = moment.duration(now.diff(then));
  const years = duration.years();
  const months = duration.months();
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  if (years > 0) {
    return years === 1 ? "1 year ago" : `${years} years ago`;
  } else if (months > 0) {
    return months === 1 ? "1 month ago" : `${months} months ago`;
  } else if (days > 0) {
    return days === 1 ? "1 day ago" : `${days} days ago`;
  } else if (hours > 0) {
    return hours === 1 ? "1 hour ago" : `${hours} hours ago`;
  } else if (minutes > 0) {
    return minutes === 1 ? "1 minute ago" : `${minutes} minutes ago`;
  } else if (seconds > 0) {
    return seconds === 1 ? "1 second ago" : `${seconds} seconds ago`;
  } else {
    return "Just now";
  }
}

export function dateFormat(value) {
  let date;
  if (Object.prototype.toString.call(value) !== "[object Date]")
    date = new Date(value);
  else date = value;

  let year = date.getFullYear(),
    month = date.getMonth() + 1,
    day = date.getDate();

  return makeTwoDigit(day) + "." + makeTwoDigit(month) + "." + year;
}

export function makeTwoDigit(val) {
  let value = "0" + val;
  return value.substring(value.length - 2);
}

export function filterUpdatedValues(defaultObject, updatedObject) {
  const filteredObject = {};
  for (const key in updatedObject) {
    if (
      (defaultObject.hasOwnProperty(key) &&
        JSON.stringify(defaultObject[key]) !==
          JSON.stringify(updatedObject[key])) ||
      key === "rowsPerPage" || key === 'infiniteScroll'
    ) {
      if (key === "dateRange") {
        filteredObject[key] = [
          format(moment(updatedObject[key][0]).toDate(), 'yyyy-MM-dd'),
          format(addDays(moment(updatedObject[key][1]).toDate(), 1), 'yyyy-MM-dd'),
        ];
      } else {
        filteredObject[key] = updatedObject[key];
      }
      // if (["duplicates", "trendFactor", "performance"].indexOf(key) >= 0) {
      //   if (updatedObject[key].length > 1 && updatedObject[key][1] === 1000) {
      //     filteredObject[key] = [updatedObject[key][0], 100000000];
      //   }
      // } else if (key === "percentage_change") {
      //   if (Array.isArray(updatedObject[key])) {
      //     let ads = updatedObject[key].slice(0, 2);
      //     let reach = updatedObject[key].slice(2, 4);
      //     if (ads[1] === 1000) ads[1] = 100000000;
      //     if (reach[1] === 1000) reach[1] = 100000000;

      //     filteredObject[key] = { ads, reach };
      //   } else {
      //     if (updatedObject[key].length > 1 && updatedObject[key][1] === 1000) {
      //       filteredObject[key] = [updatedObject[key][0], 100000000];
      //     }
      //   }
      // }
    }
  }

  return filteredObject;
}

export function filterMaxUpdatedValues(
  defaultObject,
  updatedObject,
  showEuropeFilter
) {
  let filteredObject = { ...updatedObject };
  for (const key in updatedObject) {
    if (defaultObject.hasOwnProperty(key)) {
      if (
        typeof defaultObject[key] === "object" &&
        Array.isArray(defaultObject[key])
      ) {
        if (defaultObject[key].length == 2) {
          if (defaultObject[key][1] == updatedObject[key][1]) {
            const newArray = [...filteredObject[key]];
            newArray[1] = 100000000;
            filteredObject[key] = newArray;
          }
        } else if (defaultObject[key].length == 4) {
          const newArray = [...filteredObject[key]];
          if (defaultObject[key][1] == updatedObject[key][1]) {
            newArray[1] = 100000000;
          }
          if (defaultObject[key][3] == updatedObject[key][3]) {
            newArray[3] = 100000000;
          }
          let durationAds = updatedObject.timePeriodAds
            ? updatedObject.timePeriodAds
            : "all";
          let durationReach = updatedObject.timePeriodReach
            ? updatedObject.timePeriodReach
            : "all";
          if (!showEuropeFilter) {
            durationReach = "all";
            newArray.splice(2, 2, ...defaultObject[key].slice(2, 4));
          }
          filteredObject[key] = {
            ads: [newArray[0], newArray[1]],
            reach: [newArray[2], newArray[3]],
            duration_ads: durationAds,
            duration_reach: durationReach,
          };
          delete filteredObject.timePeriodAds;
          delete filteredObject.timePeriodReach;
        }
      } else if (
        typeof defaultObject[key] === "object" &&
        !Array.isArray(defaultObject[key])
      ) {
        filteredObject[key] = { ...updatedObject[key] };
        for (const jKey in defaultObject[key]) {
          if (
            typeof defaultObject[key][jKey] === "object" &&
            Array.isArray(defaultObject[key][jKey]) &&
            defaultObject[key][jKey].length == 2
          ) {
            if (defaultObject[key][jKey][1] == updatedObject[key][jKey][1]) {
              const newArray = [...filteredObject[key][jKey]];
              newArray[1] = 100000000;
              filteredObject[key][jKey] = newArray;
            }
          }
        }
        if (
          filteredObject.percentage_change &&
          typeof defaultObject["percentage_change"] === "object" &&
          !Array.isArray(defaultObject["percentage_change"])
        ) {
          if (!showEuropeFilter) {
            let newArray = [...defaultObject.percentage_change.audienceReach];
            newArray[1] = 100000000;
            filteredObject.percentage_change = {
              ...filteredObject.percentage_change,
              audienceReach: newArray,
              duration_reach: "all",
            };
            // filteredObject.percentage_change.audienceReach = newArray;
            // filteredObject.percentage_change.duration_reach = "All";
          }

          let keyTimePair = {
            ads: "ads",
            audienceReach: "reach",
            uniqueAds: "unique",
          };
          let durationKeyField = {
            ads: "durationAd",
            audienceReach: "durationReach",
            uniqueAds: "durationUniqueAds",
          };
          for (const key in keyTimePair) {
            let durationValue =
              updatedObject.timePeriod &&
              updatedObject.timePeriod[keyTimePair[key]]
                ? updatedObject.timePeriod[keyTimePair[key]]
                : "all";

            if (!Object.isExtensible(filteredObject.percentage_change)) {
              filteredObject.percentage_change = {
                ...filteredObject.percentage_change,
              };
            }
            filteredObject.percentage_change[durationKeyField[key]] =
              durationValue;
          }
          delete filteredObject.timePeriod;
        }
      }
    }
  }

  delete filteredObject.timePeriod;

  return filteredObject;
}

export function filterUpdatedValuesExt(
  defaultObject,
  updatedObject,
  ignoreList
) {
  const filteredObject = {};

  for (const key in updatedObject) {
    if (
      ignoreList.indexOf(key) < 0 &&
      defaultObject.hasOwnProperty(key) &&
      JSON.stringify(defaultObject[key]) !== JSON.stringify(updatedObject[key])
    ) {
      filteredObject[key] = updatedObject[key];
    }
  }

  return filteredObject;
}

export const scrollToTop = (id = "") => {
  // const contentContainer = document.querySelector(
  //   ".FusePageSimple-content.container"
  // );
  // const scrollPosition = contentContainer.scrollTop;
  // contentContainer.scrollTo({
  //   top: -100,
  //   behavior: "smooth",
  // });
};

export const isValidURL = (str) => {
  var pattern = new RegExp(
    /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/
  );
  return !!pattern.test(str);
};

export function numberToCommaSeparated(number, splitter = ",") {
  // Convert the number to a string
  if (!Boolean(number)) {
    return "0";
  }
  if (typeof Number(number) !== "number" && Number(number) === NaN) {
    return "";
  }
  const numStr = String(number);

  // Use regular expression to add commas every three digits from the right
  return numStr.replace(/\B(?=(\d{3})+(?!\d))/g, splitter);
}

export function formatNumber(number) {
  if (number >= 1000000) {
    return (number / 1000000).toFixed(1) + "M";
  } else if (number >= 1000) {
    return (number / 1000).toFixed(1) + "K";
  } else {
    return number.toString();
  }
}

export function formatNumberFixed(number, fixed) {
  if (number >= 1000000) {
    let val = (number / 1000000).toFixed(fixed);
    if (parseInt(val) == val)
      return parseInt(val) + "M";
    return val + "M";
  } else if (number >= 1000) {
    let val = (number / 1000).toFixed(fixed);
    if (parseInt(val) == val)
      return parseInt(val) + "K";
    return val + "K";
  } else {
    let val = number.toFixed(fixed);
    if (parseInt(val) == val)
      return parseInt(val).toString();
    return val.toString();
  }
}

export function formatNumberWithSpaces(number) {
  return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export function formatNumberWithSpacesFixed(number, fixed) {
  let showNum = number.toFixed(fixed);
  if (parseInt(showNum) == showNum)
    return formatNumberWithSpaces(parseInt(showNum));
  else
    return formatNumberWithSpaces(showNum);
}

export const toQueryString = (filters) => {
  if (typeof filters !== "object") {
    return "";
  }
  const toStringify = (value) => {
    const notConvertablTypes = ["string", "boolean"];
    if (notConvertablTypes.includes(typeof value)) {
      return value;
    }
    return JSON.stringify(value);
  };
  const stringifiedObject = {};
  if (Object.keys(filters).length > 0) {
    for (const key in filters) {
      if (Object.hasOwnProperty.call(filters, key)) {
        stringifiedObject[key] = toStringify(filters[key]);
      }
    }
  }
  return stringifiedObject;
};

export const truncateHTMLContent = (htmlString, maxLength, disable) => {
  if (disable) {
    return htmlString;
  }
  const tempElement = document.createElement("div");
  tempElement.innerHTML = htmlString;

  const textContent = tempElement?.textContent?.trim() || "";
  const truncatedText = textContent?.substring(0, maxLength);
  const ellipsis = textContent.length > maxLength ? "..." : "";

  tempElement.innerHTML = truncatedText + ellipsis;

  return tempElement.innerHTML;
};

export const openTidio = () => {
  if (window.tidioChatApi) {
    window.tidioChatApi.show();
    window.tidioChatApi.open();
  }
};

export const showTidio = () => {
  if (window.tidioChatApi) {
    tidioChatApi.show();
    window.tidioChatApi.on("close", function () {
      tidioChatApi.show();
    });
  }
};

export const hideTidio = () => {
  const intervalId = setInterval(() => {
    if (window.tidioChatApi) {
      tidioChatApi.hide();
      window.tidioChatApi.on("ready", function () {
        tidioChatApi.hide();
      });
      window.tidioChatApi.on("close", function () {
        tidioChatApi.hide();
      });
      clearInterval(intervalId);
    }
  }, 100);
};

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const formatLikes = (likes) => {
  if (likes >= 1000000) {
    // Convert to millions (1m)
    return (likes / 1000000).toFixed(1) + "M";
  } else if (likes >= 1000) {
    // Convert to thousands (1k, 2k, etc.)
    return (likes / 1000).toFixed(likes % 1000 === 0 ? 0 : 1) + "k";
  } else {
    // No conversion needed (120 format)
    return likes;
  }
};

export const makeKiroText = (value, decimal) => {
  let showVal, unit = "";
  if (value >= 1000000) {
    // Convert to millions (1m)
    showVal = (value / 1000000).toFixed(decimal);
    unit = "M";
  } else if (value >= 1000) {
    // Convert to thousands (1k, 2k, etc.)
    showVal = (value / 1000).toFixed(decimal);
    unit = "K";
  } else {
    if (value == null) return "";
    // No conversion needed (120 format)
    showVal = value.toFixed(decimal);
  }
  for (let i = 0; i < decimal; i++) {
    if (showVal.substr(showVal.length - 1) === "0") {
      showVal = showVal.substr(0, showVal.length - 1);
    } else {
      break;
    }
  }
  if (showVal.substr(showVal.length - 1) === ".") {
    showVal = showVal.substr(0, showVal.length - 1);
  }
  return showVal + unit;
};

export const getShortcutsInfo = (
  shortcutsList,
  selectedShortcutList,
  libraryCategory,
  isReturnObj
) => {
  let shortcutNameList = shortcutsList
    .filter((shortcut) => shortcut.libraryCategory === libraryCategory)
    .map((shortcut) => shortcut.name.toLowerCase().replace(/ /g, "_"));

  let selectedShortcutInfoList = selectedShortcutList
    .filter((shortcut) => shortcutNameList.includes(shortcut))
    .map((shortcut) =>
      isReturnObj
        ? shortcutsList.find((item) => item.name === shortcut)
        : shortcutsList.find((item) => item.name === shortcut).id
    );

  return selectedShortcutInfoList;
};

export const isDarkMode = () => {
  const afterLibTheme = window.localStorage.getItem("afterlib-theme");
  return afterLibTheme === "dark";
};

export const generateSharableAdLink = (cardData, refLink) => {
  let refLinkId = "";
  if (refLink && refLink.startsWith("/ref/")) {
    refLinkId = refLink.substring(5);
  }
  return (
    window.location.origin +
    "/sharableAd?collation_id=" +
    cardData?.collationId +
    "&affiliate_link=" +
    refLinkId
  );
};

export const getRowsPerPage = (page) => {
  let rowsPerPage = window.localStorage.getItem(
    "afterlib_rows_per_page_" + page
  );
  if (!rowsPerPage || isNaN(parseInt(rowsPerPage))) return -1;
  return parseInt(rowsPerPage);
};

// export const saveRowsPerPage = (page, rowsPerPage) => {
//   window.localStorage.setItem("afterlib_rows_per_page_" + page, rowsPerPage);
// };

export const isLimitedAdvancedFilter = (subModel) => {
  const jwt_access_token = localStorage.getItem("jwt_access_token");
  const decoded_user = jwtDecode(jwt_access_token);
  return subModel < 3 && !decoded_user?.isV1User;
};

export const isV1User = () => {
  const jwt_access_token = localStorage.getItem("jwt_access_token");
  const decoded_user = jwtDecode(jwt_access_token);
  return decoded_user?.isV1User;
};

// export const getPageLastFilter = (page) => {
//   let filter = window.localStorage.getItem("afterlib_filter_" + page);
//   if (!filter) return {};
//   return JSON.parse(filter);
// };

// export const setPageLastFilter = (page, filter) => {
//   window.localStorage.setItem(
//     "afterlib_filter_" + page,
//     JSON.stringify(filter)
//   );
// };

export const getSliderPercentValue = (value, min, max, mid1, mid2) => {
  let selectedValue = [0, 0];
  if (value[0] <= max * 0.3) {
    selectedValue[0] = (mid1 * value[0]) / (max * 0.3);
  } else if (value[0] <= max * 0.7) {
    selectedValue[0] =
      mid1 + ((mid2 - mid1) * (value[0] - max * 0.3)) / (max * 0.4);
  } else {
    selectedValue[0] =
      mid2 + (max - mid2) * ((value[0] - max * 0.7) / (max * 0.3));
  }
  selectedValue[0] = parseInt(selectedValue[0], 10);

  if (value[1] <= max * 0.3) {
    selectedValue[1] = (mid1 * value[1]) / (max * 0.3);
  } else if (value[1] <= max * 0.7) {
    selectedValue[1] =
      mid1 + ((mid2 - mid1) * (value[1] - max * 0.3)) / (max * 0.4);
  } else {
    selectedValue[1] =
      mid2 + (max - mid2) * ((value[1] - max * 0.7) / (max * 0.3));
  }
  selectedValue[1] = parseInt(selectedValue[1], 10);
  return selectedValue;
};

export const getSliderPercentValue4Step = (value, min, max, mid1, mid2, mid3) => {
  let selectedValue = [0, 0];
  if (value[0] <= max * 0.25) {
    selectedValue[0] = (mid1 * value[0]) / (max * 0.25);
  } else if (value[0] <= max * 0.5) {
    selectedValue[0] =
      mid1 + ((mid2 - mid1) * (value[0] - max * 0.25)) / (max * 0.25);
  } else if (value[0] <= max * 0.75) {
    selectedValue[0] =
      mid2 + ((mid3 - mid2) * (value[0] - max * 0.5)) / (max * 0.25);
  } else {
    selectedValue[0] =
      mid3 + (max - mid3) * ((value[0] - max * 0.75) / (max * 0.25));
  }
  selectedValue[0] = parseInt(selectedValue[0], 10);

  if (value[1] <= max * 0.25) {
    selectedValue[1] = (mid1 * value[1]) / (max * 0.25);
  } else if (value[1] <= max * 0.5) {
    selectedValue[1] =
      mid1 + ((mid2 - mid1) * (value[1] - max * 0.25)) / (max * 0.25);
  } else if (value[1] <= max * 0.75) {
    selectedValue[1] =
      mid2 + ((mid3 - mid2) * (value[1] - max * 0.5)) / (max * 0.25);
  } else {
    selectedValue[1] =
      mid3 + (max - mid3) * ((value[1] - max * 0.75) / (max * 0.25));
  }
  selectedValue[1] = parseInt(selectedValue[1], 10);
  return selectedValue;
};

export const getSliderPercentValueAudienceReach = (value, min, max, mid1, mid2, mid3) => {
  let selectedValue = [0, 0];
  if (value[0] <= max * 0.5) {
    selectedValue[0] = (mid1 * value[0]) / (max * 0.5);
  } else if (value[0] <= max * 0.75) {
    selectedValue[0] =
      mid1 + ((mid2 - mid1) * (value[0] - max * 0.5)) / (max * 0.25);
  } else if (value[0] <= max * 0.95) {
    selectedValue[0] =
      mid2 + ((mid3 - mid2) * (value[0] - max * 0.75)) / (max * 0.2);
  } else {
    selectedValue[0] =
      mid3 + (max - mid3) * ((value[0] - max * 0.95) / (max * 0.05));
  }
  selectedValue[0] = parseInt(selectedValue[0], 10);

  if (value[1] <= max * 0.5) {
    selectedValue[1] = (mid1 * value[1]) / (max * 0.5);
  } else if (value[1] <= max * 0.75) {
    selectedValue[1] =
      mid1 + ((mid2 - mid1) * (value[1] - max * 0.5)) / (max * 0.25);
  } else if (value[1] <= max * 0.95) {
    selectedValue[1] =
      mid2 + ((mid3 - mid2) * (value[1] - max * 0.75)) / (max * 0.2);
  } else {
    selectedValue[1] =
      mid3 + (max - mid3) * ((value[1] - max * 0.95) / (max * 0.05));
  }
  selectedValue[1] = parseInt(selectedValue[1], 10);
  return selectedValue;
};

export const getSliderPercentV = (value, min, max, mid1, mid2) => {
  let selectedValue = 0;
  if (value <= max * 0.3) {
    selectedValue = (mid1 * value) / (max * 0.3);
  } else if (value <= max * 0.7) {
    selectedValue = mid1 + ((mid2 - mid1) * (value - max * 0.3)) / (max * 0.4);
  } else {
    selectedValue = mid2 + (max - mid2) * ((value - max * 0.7) / (max * 0.3));
  }
  return parseInt(selectedValue, 10);
};

export const getSliderPercentV4Step = (value, min, max, mid1, mid2, mid3) => {
  let selectedValue = 0;
  if (value <= max * 0.25) {
    selectedValue = (mid1 * value) / (max * 0.25);
  } else if (value <= max * 0.5) {
    selectedValue = mid1 + ((mid2 - mid1) * (value - max * 0.25)) / (max * 0.25);
  } else if (value <= max * 0.75) {
    selectedValue = mid2 + (mid3 - mid2) * ((value - max * 0.5) / (max * 0.25));
  } else {
    selectedValue = mid3 + (max - mid3) * ((value - max * 0.75) / (max * 0.25));
  }
  return parseInt(selectedValue, 10);
};

export const getSliderPercentAudienceReach = (value, min, max, mid1, mid2, mid3) => {
  let selectedValue = 0;
  if (value <= max * 0.5) {
    selectedValue = (mid1 * value) / (max * 0.5);
  } else if (value <= max * 0.75) {
    selectedValue = mid1 + ((mid2 - mid1) * (value - max * 0.5)) / (max * 0.25);
  } else if (value <= max * 0.95) {
    selectedValue = mid2 + (mid3 - mid2) * ((value - max * 0.75) / (max * 0.2));
  } else {
    selectedValue = mid3 + (max - mid3) * ((value - max * 0.95) / (max * 0.05));
  }
  return parseInt(selectedValue, 10);
};

export const getSliderRealValue = (value, min, max, mid1, mid2) => {
  let selectedValue = [0, 0];
  if (value[0] <= mid1) {
    selectedValue[0] = (max * 0.3 * value[0]) / mid1;
  } else if (value[0] <= mid2) {
    selectedValue[0] =
      max * 0.3 + (max * 0.4 * (value[0] - mid1)) / (mid2 - mid1);
  } else {
    selectedValue[0] =
      max * 0.7 + (max * 0.3 * (value[0] - mid2)) / (max - mid2);
  }
  selectedValue[0] = parseInt(selectedValue[0], 10);

  if (value[1] <= mid1) {
    selectedValue[1] = (max * 0.3 * value[1]) / mid1;
  } else if (value[1] <= mid2) {
    selectedValue[1] =
      max * 0.3 + (max * 0.4 * (value[1] - mid1)) / (mid2 - mid1);
  } else {
    selectedValue[1] =
      max * 0.7 + (max * 0.3 * (value[1] - mid2)) / (max - mid2);
  }
  selectedValue[1] = parseInt(selectedValue[1], 10);
  return selectedValue;
};

export const getSliderRealValue4Step = (value, min, max, mid1, mid2, mid3) => {
  
  let selectedValue = [0, 0];
  if (value[0] <= mid1) {
    selectedValue[0] = (max * 0.25 * value[0]) / mid1;
  } else if (value[0] <= mid2) {
    selectedValue[0] =
      max * 0.25 + (max * 0.25 * (value[0] - mid1)) / (mid2 - mid1);
  } else if (value[0] <= mid3) {
    selectedValue[0] =
      max * 0.5 + (max * 0.25 * (value[0] - mid2)) / (mid3 - mid2);
  } else {
    selectedValue[0] =
      max * 0.75 + (max * 0.25 * (value[0] - mid3)) / (max - mid3);
  }
  selectedValue[0] = parseInt(selectedValue[0], 10);

  if (value[1] <= mid1) {
    selectedValue[1] = (max * 0.25 * value[1]) / mid1;
  } else if (value[1] <= mid2) {
    selectedValue[1] =
      max * 0.25 + (max * 0.25 * (value[1] - mid1)) / (mid2 - mid1);
  } else if (value[1] <= mid3) {
    selectedValue[1] =
      max * 0.5 + (max * 0.25 * (value[1] - mid2)) / (mid3 - mid2);
  } else {
    selectedValue[1] =
      max * 0.75 + (max * 0.25 * (value[1] - mid3)) / (max - mid3);
  }
  selectedValue[1] = parseInt(selectedValue[1], 10);
  
  return selectedValue;
};

export const getSliderRealValueAudienceReach = (value, min, max, mid1, mid2, mid3) => {
  
  let selectedValue = [0, 0];
  if (value[0] <= mid1) {
    selectedValue[0] = (max * 0.5 * value[0]) / mid1;
  } else if (value[0] <= mid2) {
    selectedValue[0] =
      max * 0.5 + (max * 0.25 * (value[0] - mid1)) / (mid2 - mid1);
  } else if (value[0] <= mid3) {
    selectedValue[0] =
      max * 0.75 + (max * 0.2 * (value[0] - mid2)) / (mid3 - mid2);
  } else {
    selectedValue[0] =
      max * 0.95 + (max * 0.05 * (value[0] - mid3)) / (max - mid3);
  }
  selectedValue[0] = parseInt(selectedValue[0], 10);

  if (value[1] <= mid1) {
    selectedValue[1] = (max * 0.5 * value[1]) / mid1;
  } else if (value[1] <= mid2) {
    selectedValue[1] =
      max * 0.5 + (max * 0.25 * (value[1] - mid1)) / (mid2 - mid1);
  } else if (value[1] <= mid3) {
    selectedValue[1] =
      max * 0.75 + (max * 0.2 * (value[1] - mid2)) / (mid3 - mid2);
  } else {
    selectedValue[1] =
      max * 0.95 + (max * 0.05 * (value[1] - mid3)) / (max - mid3);
  }
  selectedValue[1] = parseInt(selectedValue[1], 10);
  
  return selectedValue;
};
