import { lazy, Suspense } from "react";
import FuseLoading from "@fuse/core/FuseLoading/FuseLoading";
import { authRoles } from "app/auth";

const HomePage = lazy(() => import("./HomePage"));

const HomeConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        toolbar_small: {
          display: false,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  auth: authRoles.onlyGuest,
  routes: [
    {
      path: "",
      element: (
        <Suspense fallback={<FuseLoading />}>
          <HomePage />
        </Suspense>
      ),
    },
  ],
};

export default HomeConfig;
