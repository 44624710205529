import { ThemeProvider } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Hidden from "@mui/material/Hidden";
import Toolbar from "@mui/material/Toolbar";
import clsx from "clsx";
import { memo } from "react";
import { useSelector } from "react-redux";
import {
  selectFuseCurrentLayoutConfig,
  selectToolbarTheme,
} from "app/store/fuse/settingsSlice";
import { selectFuseNavbar } from "app/store/fuse/navbarSlice";
import NavbarToggleButton from "../../shared-components/NavbarToggleButton";
import UserMenu from "../../shared-components/UserMenu";
import { Box, Typography } from "@mui/material";
import { ArrowForwardIcon } from "app/shared-components/svgs";
import { useNavigate } from "react-router-dom";
import { selectBreadcrumbs } from "app/store/fuse/breadcrumbsSlice";

function ToolbarLayout1(props) {
  const navigate = useNavigate();
  const config = useSelector(selectFuseCurrentLayoutConfig);
  const navbar = useSelector(selectFuseNavbar);
  const toolbarTheme = useSelector(selectToolbarTheme);
  const breadCrumbs = useSelector(selectBreadcrumbs);

  // const breadCrumbs = [
  //   {
  //     id: 1,
  //     name: "Ads Library",
  //     path: "/ads",
  //     type: "redirect",
  //     onClick: () => {
  //       console.log("redirect hello");
  //     },
  //     isCurrentPage: false,
  //   },
  //   {
  //     id: 2,
  //     name: "Ads Library",
  //     path: "/ads",
  //     type: "onClick",
  //     onClick: () => {
  //       console.log("onclick hello");
  //     },
  //     isCurrentPage: false,
  //   },
  //   {
  //     id: 3,
  //     name: "Pages",
  //     path: "/ads",
  //     type: "onClick",
  //     onClick: () => {
  //       console.log("current hello");
  //     },
  //     isCurrentPage: true,
  //   },
  // ];

  const handleBreadCrumbClick = ({ type, path, onClick, isCurrentPage }) => {
    if (isCurrentPage) {
      return;
    }
    if (type === "redirect") {
      navigate(path);
      return;
    }
    if (type === "onClick") {
      onClick();
      return;
    }
  };

  return (
    <ThemeProvider theme={toolbarTheme}>
      <AppBar
        id="fuse-toolbar"
        className={clsx(
          "flex relative z-20 shadow-0 border-b-1 h-48 md:h-48 justify-center",
          props.className
        )}
        color="default"
        sx={{
          backgroundColor: (theme) => toolbarTheme.palette.background.paper,
        }}
        position="static"
      >
        <Toolbar className="p-0 min-h-48 md:min-h-64">
          <div className="flex flex-1 px-16 items-center">
            {config.navbar.display && config.navbar.position === "left" && (
              <>
                <Hidden lgDown>
                  {(config.navbar.style === "style-3" ||
                    config.navbar.style === "style-3-dense") && (
                    <NavbarToggleButton className="w-40 h-40 p-0 mx-0" />
                  )}

                  {config.navbar.style === "style-1" && !navbar.open && (
                    <NavbarToggleButton className="w-40 h-40 p-0 mx-0" />
                  )}
                </Hidden>

                <Hidden lgUp>
                  <NavbarToggleButton className="w-40 h-40 p-0 mx-0 sm:mx-8" />
                </Hidden>
                {breadCrumbs.map((bc, i) => (
                  <Box className="flex items-center space-x-8" key={i}>
                    <Typography
                      className={`ml-12 font-500 text-12 ${
                        breadCrumbs.length === i + 1 && "opacity-80"
                      } ${!bc.isCurrentPage && "cursor-pointer"}`}
                      color="text.primary"
                      onClick={() => handleBreadCrumbClick(bc)}
                    >
                      {bc.name}
                    </Typography>
                    {i + 2 <= breadCrumbs.length && <ArrowForwardIcon />}
                  </Box>
                ))}
              </>
            )}
          </div>

          <div className="flex items-center px-8 h-full overflow-x-auto">
            <UserMenu />
          </div>

          {config.navbar.display && config.navbar.position === "right" && (
            <>
              <Hidden lgDown>
                {!navbar.open && (
                  <NavbarToggleButton className="w-40 h-40 p-0 mx-0" />
                )}
              </Hidden>
              <Hidden lgUp>
                <NavbarToggleButton className="w-40 h-40 p-0 mx-0 sm:mx-8" />
              </Hidden>
            </>
          )}
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
}

export default memo(ToolbarLayout1);
