import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import JwtService from "../auth/services/jwtService";

const customFetchBaseQuery = fetchBaseQuery({
  baseUrl: import.meta.env.REACT_APP_API_URL,
  prepareHeaders: (headers) => {
    if (JwtService.getAccessToken()) {
      headers.set("Authorization", `Bearer ${JwtService.getAccessToken()}`);
    } else {
      headers.delete("Authorization");
    }
    return headers;
  },
});

export default customFetchBaseQuery;
