const jwtServiceConfig = {
  signIn: "/user/login",
  signUp: "/user/register",
  confirm: "/user/confirm",
  resendConfimation: "/user/resend_confirmation",
  checkPaymentStatus: "/check_subscription_status",
  refreshToken: "/user/refresh_token",
  forgetPassword: "/user/forgot_password",
  resetPassword: "/user/reset_password",
  updateUser: "api/auth/user/update",
  confirmInvitation: "/confirm-invitation",
  signUpWithInvitation: "/user/register-with-invitation",
};

export default jwtServiceConfig;
