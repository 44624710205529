import Switch from "@mui/material/Switch";
import styled, { css } from "styled-components";

export const CustomCheckbox = styled(Switch)`
  width: 61px;
  height: 47px;
  &.narrow: {
    marginleft: -4px !important;
  }
  ,
  & .MuiButtonBase-root:hover {
    background-color: transparent;
  }
  & .MuiSwitch-thumb {
    width: 19px;
    height: 19px;
    transform: translate(5px, 5px);
    border: none;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: none;
    box-shadow: none;
  }
  & .MuiSwitch-track {
    border-radius: 30px;
    opacity: 0.2;
  }
  & .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    opacity: 1;
    background-color: #50cd89;
  }
  & .MuiSwitch-switchBase.Mui-checked {
    transform: translateX(13px);
  }
  & .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb {
    background-color: #fff;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15), 0px 3px 1px rgba(0, 0, 0, 0.06) !important;
    ${(props) =>
      props.is_success === "true"
        ? css`
            background-image: url("/assets/icons/tick-success.svg");
          `
        : css`
            background-image: url("/assets/icons/tick.svg");
          `};
  }
`;

export default CustomCheckbox;
