import packageData from "app/shared-components/static/packageData";

const permissions = {
  diamond: ["diamond", "admin"],
  pro: ["pro", "diamond", "admin"],
  basic: ["trial", "intro", "basic", "pro", "diamond", "admin"],
};

const getCurrentPlan = (pkgNo, isAdmin, membership) => {
  if (isAdmin) {
    return "admin";
  }
  if (membership === 2) {
    return "trial";
  }
  const currentPackage = packageData[0].packages.find(
    (pkg) => pkg.id === pkgNo
  );
  if (currentPackage) {
    return currentPackage.name.toLowerCase();
  }
  // TODO: make it "" string again
  return "basic";
};

const hasPermission = (permission = "basic", role) => {
  const currentPermissionArray = permissions[permission];
  if (!currentPermissionArray) {
    return true;
  }
  const _hasPermission = currentPermissionArray.includes(role);
  return _hasPermission;
};

export { getCurrentPlan, permissions, hasPermission };
